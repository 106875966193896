
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      verificationCode: '',
      newPassword: ''
    }
  },
  methods: {
    async onConfirmation() {
      if (!this.verificationCode || !this.newPassword) {
        return
      }
      this.$emit("onConfirmation", this.verificationCode, this.newPassword);
      this.verificationCode = ''
      this.newPassword = ''
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("toggleDialog", false);
      this.$emit("update:dialog", false);
    },
  },
  watch: {},
});
