
import Vue from "vue";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { Actions, Mutations } from "@/store/models";
import ForgotPasswordDialog from "@/components/login/ForgotPasswordDialog.vue";
import ResetPasswordDialog from "@/components/login/ResetPasswordDialog.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { AssociateType, HeaderKeys, removeHeader, setHeaders } from "@/api";
import { navs } from "@/mixins";
import Logo from "@/components/common/Logo.vue";
export default Vue.extend({
  name: "Login",
  mixins: [navs],
  components: {
    ForgotPasswordDialog,
    ResetPasswordDialog,
    ConfirmationDialog,
    Logo,
  },
  data() {
    return {
      logo: require("/src/assets/gssi-emblem.svg"),
      image: require("/src/assets/Login-new.jpg"),
      username: "",
      password: "",
      showPassword: false,
      forgotPasswordDialog: false,
      newPasswordDialog: false,
      resetPasswordDialog: false,
      cognitoUser: undefined as any,
      accessToken: undefined as any,

      activeSessionDialog: false,
      activeSessionMessage: "Authentication Error",
    };
  },
  computed: {
    btnText: function (): string {
      if (this.$route.query.accountTransfer) {
        return "CONTINUE";
      } else {
        return "LOGIN";
      }
    },
  },
  methods: {
    async init(forceCurrentSession?: boolean) {
      if (forceCurrentSession) {
        setHeaders([
          {
            key: HeaderKeys.ForceActivateCurrentUserSession,
            value: "true",
          },
        ]);
      }
      const { initialized, msg } = await this.$store.dispatch(
        Actions.Init,
        this.accessToken
      );
      if (forceCurrentSession) {
        removeHeader(HeaderKeys.ForceActivateCurrentUserSession);
      }
      if (initialized) {
        (this as any).goToInitial();
      } else if (msg) {
        this.activeSessionMessage = msg || "Authentication Error";
        this.activeSessionDialog = true;
      }
    },
    login() {
      console.log("Login function called");
      if (process.env.NODE_ENV === "development") {
        switch (this.username) {
          case "111":
            this.accessToken = "admin1@abc.com";
            break;
          case "222":
            this.accessToken = "cao@abc.com";
            break;
          case "333":
            this.accessToken = "admin@gssi.com";
            break;
          case "444":
            this.accessToken = "sysadmin@gssi.com";
            break;
        }

        console.log("env: ", process.env.NODE_ENV);
        console.log("accesstoekn: ", this.accessToken);

        if (this.accessToken) {
          this.init();
          return;
        }
      }

      console.log("ISLOADING");
      this.$store.commit(Mutations.SetIsLoading, true);

      this.username = this.username.trim();
      this.password = this.password.trim();
      const authenticationDetails = new AuthenticationDetails({
        Username: this.username,
        Password: this.password,
      });
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.VUE_APP_USER_POOL_ID,
        ClientId: process.env.VUE_APP_CLIENT_ID,
      });
      const cognitoUser = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });
      console.log("authenticating: ", this.username);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          console.log("login successful", result);
          this.accessToken = (result.getAccessToken() as any).jwtToken;
          this.init();
          this.$store.commit(Mutations.SetIsLoading, false);
        },
        onFailure: (err) => {
          console.log("login failed", err);
          this.$store.dispatch(
            Actions.DisplayError,
            // err.message || JSON.stringify(err)
            "Incorrect username or password."
          );
          this.$store.commit(Mutations.SetIsLoading, false);
        },
      });
    },
    goToForgotPassword() {
      this.$router.push("/forgotpassword");
    },
    forgotPassword(username: string) {
      this.username = username.trim();

      const userPool = new CognitoUserPool({
        UserPoolId: process.env.VUE_APP_USER_POOL_ID,
        ClientId: process.env.VUE_APP_CLIENT_ID,
      });
      this.cognitoUser = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      }) as CognitoUser;

      // call forgotPassword on cognitoUser
      this.cognitoUser.forgotPassword({
        onSuccess: (result: any) => {
          this.$store.commit(Mutations.SetToast, {
            text: "Password reset successful.",
          });
        },
        onFailure: (err: any) => {
          this.$store.dispatch(
            Actions.DisplayError,
            err.message || JSON.stringify(err)
          );
        },
        inputVerificationCode: () => {
          this.resetPasswordDialog = true;
        },
      });
    },
    resetPassword(verificationCode: string, newPassword: string) {
      verificationCode = verificationCode.trim();
      newPassword = newPassword.trim();

      // call confirmPassword on cognitoUser
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (result: any) => {
          this.$store.commit(Mutations.SetToast, {
            text: "Password reset successful.",
          });
        },
        onFailure: (err: any) => {
          this.$store.dispatch(
            Actions.DisplayError,
            err.message || JSON.stringify(err)
          );
        },
      });
    },
    signup() {
      this.$router.push("/signup");
    },
  },
});
